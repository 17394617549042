import { LucideChevronDown, LucideChevronUp } from 'lucide-react'
import { CSSProperties, useEffect, useRef, useState } from 'react'
import { responsiveBreakpointMap } from '~/constants'
import { COLORS } from '~/constants/colors'
import { useGetDeviceWidth, useOnClickOutside } from '~/hooks'
import { Spacer } from '../Common/Spacer'
import { SidebarListSubitem } from './SidebarListSubitem'

interface SidebarListItemProps {
    /**
     * List item text
     */
    children: string
    /**
     * List item icon
     */
    icon?: JSX.Element
    /**
     * List item badge
     */
    badge?: JSX.Element
    /**
     * Squeeze list item
     */
    shrink?: boolean
    /**
     * Responsive breakpoint
     */
    responsive?: keyof typeof responsiveBreakpointMap
    /**
     * List item active state
     */
    active?: boolean
    /**
     * List of subitem
     */
    subitem?: JSX.Element[]
    /**
     * Optional click event
     */
    onClick?: () => void
}

export const SidebarListItem = ({
    children,
    icon,
    badge,
    shrink = false,
    responsive = 'sm',
    active = false,
    subitem = [],
    onClick
}: SidebarListItemProps) => {
    const wrapper = useRef<HTMLLIElement>(null)
    const [showSubitem, setShowSubitem] = useState(false)
    const [subitemStyle, setSubitemStyle] = useState<CSSProperties>({})
    const [deviceWidth, setDeviceWidth] = useState(0)

    useGetDeviceWidth(setDeviceWidth, () => {
        handleClickOutside()
    })

    const isResponsiveView = deviceWidth !== 0 && deviceWidth <= responsiveBreakpointMap[responsive]

    const isShrinkedView = !isResponsiveView && shrink

    useEffect(() => {
        setShowSubitem(false)
        setSubitemStyle({})
    }, [isShrinkedView])

    const handleClick = () => {
        if (!!subitem.length) {
            if (isShrinkedView) {
                toggleShowSubitem()
                return
            }
            setShowSubitem(!showSubitem)
            return
        }

        onClickEvent()
    }

    const onClickEvent = () => {
        if (!onClick) {
            return
        }

        onClick()
    }

    const toggleShowSubitem = () => {
        setShowSubitem(true)

        if (!showSubitem) {
            if (!wrapper) {
                return
            }
            const element = wrapper.current

            if (!element) {
                return
            }

            const rect = element.getBoundingClientRect()
            const top = rect.top
            const left = rect.right

            setSubitemStyle({
                top,
                left,
                position: 'fixed',
                width: 200,
                zIndex: 1000,
                border: 'thin solid' + COLORS.MAIN.NEUTRALS[200]
            })
        }
    }

    const handleClickOutside = () => {
        if (isShrinkedView) {
            setShowSubitem(false)
            setSubitemStyle({})
        }
    }

    useOnClickOutside(wrapper, handleClickOutside)

    return (
        <li ref={wrapper}>
            <a
                className={`flex no-underline items-center px-2 text-base leading-normal relative font-medium ${
                    active
                        ? 'text-primary-600 bg-primary-100 hover:text-primary-600'
                        : 'text-neutrals-900 hover:bg-primary-100'
                } ${isShrinkedView ? 'py-2 justify-center' : 'py-2 rounded-md'}`}
                role="button"
                onClick={() => handleClick()}>
                {isShrinkedView && active && (
                    <div className="h-8 w-1 bg-primary-600 rounded-r-sm absolute left-0 top-1/2 transform -translate-y-1/2"></div>
                )}
                {!!icon && <span className={isShrinkedView ? '' : 'mr-3 inline-flex items-center'}>{icon}</span>}
                {!shrink && (
                    <>
                        <span>{children}</span>
                        <Spacer></Spacer>
                    </>
                )}
                {!!badge && (
                    <span className={isShrinkedView ? 'absolute flex top-1 right-1.5 w-5 h-5' : 'mr-1'}>{badge}</span>
                )}
                {!isShrinkedView && !!subitem.length && (
                    <span
                        className="flex items-center justify-center"
                        role="button"
                        onClick={(e) => {
                            e.stopPropagation()
                            setShowSubitem(!showSubitem)
                        }}>
                        {!showSubitem ? (
                            <LucideChevronDown size={16}></LucideChevronDown>
                        ) : (
                            <LucideChevronUp size={16}></LucideChevronUp>
                        )}
                    </span>
                )}
            </a>
            {!!subitem.length && (!isShrinkedView || (isShrinkedView && showSubitem)) && (
                <ul
                    className={`grid gap-2 grid-cols-1 ${isShrinkedView && showSubitem ? 'py-2' : 'pt-2'} ${
                        !isShrinkedView && !showSubitem && 'hidden'
                    }`}
                    style={subitemStyle}
                    onClick={(e) => {
                        e.stopPropagation()
                        handleClickOutside()
                    }}>
                    {isShrinkedView && (
                        <SidebarListSubitem active={active} badge={badge} onClick={() => onClickEvent()}>
                            {children}
                        </SidebarListSubitem>
                    )}
                    {subitem}
                </ul>
            )}
        </li>
    )
}
